<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs"
         v-on="on"
         @click.prevent
         class="primary--text">
        privacy verklaring
      </a>
    </template>

    <v-card>
      <v-card-title class="text-h6">Privacy verklaring</v-card-title>
      <v-card-text>
        FIS Automotive gaat zorgvuldig met je persoonlijke gegevens om.

        <h5 class="text-h6">Door gebruik te maken van deze site ga je akkoord met ons privacy- en cookie-beleid.</h5>
        <p>FIS AUTOMOTIVE, gevestigd aan SLIBBROEK 44, 5081 NS HILVARENBEEK, is verantwoordelijk voor de verwerking
          van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>

        <span class="font-weight-bold text-subtitle-2">Contactgegevens:</span>
        <p>
          FIS AUTOMOTIVE <br/>
          Slibbroek 44 <br/>
          5081 NS Hilvarenbeek <br/>
          Telefoon: 013-505 3272
        </p>

        <span class="font-weight-bold text-subtitle-2">Persoonsgegevens die wij verwerken</span>
        <p>FIS AUTOMOTIVE verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze
          zelf aan ons verstrekt.</p>

        <span class="font-weight-bold text-subtitle-2">Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:</span>
        <ul class="mb-2">
          <li>Voor- en achternaam</li>
          <li>Geslacht</li>
          <li>Adres gegevens</li>
          <li>Telefoonnummer</li>
          <li>E-mail</li>
        </ul>

        <span class="font-weight-bold text-subtitle-2">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</span>
        <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn
          dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een
          bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun
          kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als
          u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een
          minderjarige, neem dan contact met ons op via INFO@FISAUTOMOTIVE.NL, dan verwijderen wij deze
          informatie.</p>

        <span class="font-weight-bold text-subtitle-2">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</span>
        FIS AUTOMOTIVE verwerkt uw persoonsgegevens voor de volgende doelen:
        <ul class="mb-2">
          <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
          <li>U de mogelijkheid te bieden een account aan te maken</li>
        </ul>

        <span class="font-weight-bold text-subtitle-2">Geautomatiseerde besluitvorming</span>
        <p>FIS AUTOMOTIVE neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die
          (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door
          computerprogramma’s of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van FIS AUTOMOTIVE)
          tussen zit.</p>

        <span class="font-weight-bold text-subtitle-2">Hoe lang we persoonsgegevens bewaren</span>
        <p>FIS AUTOMOTIVE bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren
          waarvoor uw gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën)
          van persoonsgegevens:</p>
        <ul class="mb-2">
          <li>Voor- en achternaam
            <ul>
              <li>Termijn: &lt; 2 jaar</li>
              <li>Reden: Administratie en contactmogelijkheden</li>
            </ul>
          </li>
          <li> Geslacht (
            <span class="font-weight-bold">indien van toepassing</span>
            )
            <ul>
              <li>Termijn: &lt; 2 jaar</li>
              <li>Reden: Administratie en contactmogelijkheden</li>
            </ul>
          </li>
          <li>Voor- en achternaam
            <ul>
              <li>Termijn: &lt; 2 jaar</li>
              <li>Reden: Administratie en contactmogelijkheden</li>
            </ul>
          </li>
          <li>Voor- en achternaam
            <ul>
              <li>Termijn: &lt; 2 jaar</li>
              <li>Reden: Administratie en contactmogelijkheden</li>
            </ul>
          </li>
          <li>Voor- en achternaam
            <ul>
              <li>Termijn: &lt; 2 jaar</li>
              <li>Reden: Administratie en contactmogelijkheden, nieuwsbrief mogelijkheden</li>
            </ul>
          </li>
        </ul>

        <span class="font-weight-bold text-subtitle-2">Delen van persoonsgegevens met derden</span>
        <p>FIS AUTOMOTIVE verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze
          overeenkomst met u of om te voldoen aan een wettelijke verplichting.</p>

        <span class="font-weight-bold text-subtitle-2">Cookies, of vergelijkbare technieken, die wij gebruiken</span>
        <p>FIS AUTOMOTIVE gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand
          dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of
          smartphone. FIS AUTOMOTIVE gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat
          de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies
          worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we
          cookies die uw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden.
        </p>
        <p>Bij uw eerste bezoek aan onze website hebben wij u al geïnformeerd over deze cookies en toestemming gevraagd
          voor het plaatsen ervan.
        </p>
        <p>U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer
          opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser
          verwijderen.
        </p>
        <p>Op deze website worden ook cookies geplaatst door derden. Dit zijn bijvoorbeeld adverteerders en/of de
          sociale media-bedrijven. Hieronder een overzicht:
        </p>
        <p>Op deze website worden ook cookies geplaatst door derden. Dit zijn bijvoorbeeld adverteerders en/of de
          sociale media-bedrijven. Hieronder een overzicht:
        </p>
        <p>Cookie: Google Analytics <br/>
          Naam: _ga <br/>
          Functie: Analytische cookie die websitebezoek meet <br/>
          Bewaartermijn: 2 jaar</p>

        <span class="font-weight-bold text-subtitle-2">Gegevens inzien, aanpassen of verwijderen</span>
        <p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het
          recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
          verwerking van uw persoonsgegevens door FIS AUTOMOTIVE en heeft u het recht op gegevensoverdraagbaarheid.
          Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een
          computerbestand naar u of een ander, door u genoemde organisatie, te sturen.
        </p>
        <p> U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of
          verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar
          INFO@FISAUTOMOTIVE.NL.
        </p>
        <p> Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw
          identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de
          strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter
          bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek.
        </p>
        <p> FIS AUTOMOTIVE wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de
          nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link:
          https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
        </p>

        <span class="font-weight-bold text-subtitle-2">Hoe wij persoonsgegevens beveiligen</span>
        <p> FIS AUTOMOTIVE neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik,
          verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de
          indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact
          op met onze klantenservice of via INFO@FISAUTOMOTIVE.NL
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary"
               text
               @click="dialog = false">
          Sluiten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PrivacyPolicyDialog',
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
