<template>
  <v-row justify="center" class="my-4 mx-0">
    <v-col cols="12" md="4">
      <v-alert type="success" v-show="isSuccessful">
        Er is succesvol een vraag gesteld
      </v-alert>
      <ContactForm v-model="values" ref="contactForm"/>

      <v-row no-gutters justify="end" class="mt-4">
        <v-btn color="primary" dark :loading="loading" @click="submitQuestion">
          Verzenden
        </v-btn>
      </v-row>
    </v-col>

    <v-col cols="auto">
      <VDivider vertical/>
    </v-col>

    <v-col cols="12" md="4">
      <h2 class="text-h4 mb-4">Contactgegevens</h2>

      <v-alert icon="fa-phone" dense>
        013-505 32 72
      </v-alert>
      <v-alert icon="fa-envelope" dense>
        info@fisautomotive.nl
      </v-alert>
      <v-alert icon="fa-location-arrow" dense>
        <FisAddressInformation/>
      </v-alert>
      <v-alert icon="fa-clock" dense>
        <table class="ma-0 pa-0">
          <tr>
            <td>Maandag <br/>
            <td> 8:00 - 17:00</td>
          </tr>
          <tr>
            <td>Dinsdag</td>
            <td> 8:00 - 17:00</td>
          </tr>
          <tr>
            <td>Woensdag</td>
            <td> 8:00 - 17:00</td>
          </tr>
          <tr>
            <td>Donderdag</td>
            <td> 8:00 - 17:00</td>
          </tr>
          <tr>
            <td>Vrijdag</td>
            <td> 8:00 - 17:00</td>
          </tr>
          <tr>
            <td class="pr-4">Zaterdag, zondag</td>
            <td>gesloten</td>
          </tr>
        </table>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { store } from '@/api/contact';
import FisAddressInformation from '@/components/FisAddressInformation.vue';
import ContactForm from '@/components/forms/Contact.vue';
import { Contact } from '@/application/models/Contact.js';

export default {
  name: 'Contact',
  components: {
    ContactForm,
    FisAddressInformation,
  },
  created() {
    this.$hero.$emit('setTitle', 'Contact');
    this.$hero.$emit('setCanSearch', false);
    this.setBreadCrumbs();
  },
  mounted() {
    this.toggleReCaptchaBadge();
  },
  data: () => ({
    isSuccessful: false,
    loading: false,
    values: new Contact(),
  }),
  destroyed() {
    this.toggleReCaptchaBadge(true);
  },
  methods: {
    async submitQuestion() {
      this.$refs.contactForm?.validate();

      if (this.$refs.contactForm?.valid) {
        this.loading = true;
        try {
          await store(this.values);
          this.isSuccessful = true;
          this.$refs.contactForm.clear();
        } catch {
          this.isSuccessful = false;
          this.$refs.contactForm.validate();
        }
        this.loading = false;
      }
    },
    toggleReCaptchaBadge(hide = false) {
      const recaptcha = this.$recaptchaInstance;
      if (!recaptcha) {
        return;
      }

      if (hide) {
        this.$recaptchaInstance.hideBadge();
      } else {
        this.$recaptchaInstance.showBadge();
      }
    },
    setBreadCrumbs() {
      this.$hero.$emit('setBreadcrumbs', [
        {
          text: 'home',
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'contact',
          to: {
            name: 'contact',
          },
        },
      ]);
    },
  },
};
</script>

<style scoped lang="scss">
table {
  td:last-child {
    font-weight: bold;
  }
}
</style>
