<template>
<div>
  <v-form ref="form" v-model="valid">
    <v-text-field label="Naam"
                  v-model="contact.name"
                  :rules="[(v) => !!v || 'Naam is verplicht', v => serverError('name', v)]"
                  class="required"
    ></v-text-field>
    <v-text-field label="E-mail"
                  v-model="contact.email"
                  :rules="[(v) => !!v || 'E-mail is verplicht', v => serverError('email', v)]"
                  class="required"
    ></v-text-field>
    <v-text-field label="Bedrijfsnaam"
                  :rules="[v => serverError('company', v)]"
                  v-model="contact.company"
    ></v-text-field>
    <v-text-field label="Telefoon"
                  v-model="contact.phone"
                  :rules="[(v) => !!v || 'Telefoon is verplicht', v => serverError('phone', v)]"
                  class="required"
    ></v-text-field>
    <v-textarea label="Vraag"
                v-model="contact.question"
                :rules="[(v) => !!v || 'Uw vraag is verplicht', v => serverError('question', v)]"
                class="required"
    ></v-textarea>

    <v-checkbox v-model="contact.privacyPolicyAgreement"
                :rules="[(v) => !!v || 'U moet akkoord gaan met de privacy verklaring', v => serverError('privacyPolicyAgreement', v)]"
                class="required">
      <template #label>
        <span>Ik ga akkoord met de <PrivacyPolicyDialog/> </span>
      </template>
    </v-checkbox>
  </v-form>
  <small>* = velden zijn verplicht</small>
</div>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue';
import PrivacyPolicyDialog from '@/components/dialogs/PrivacyPolicy.vue';

export default {
  name: 'ContactForm',
  extends: BaseForm,
  components: {
    PrivacyPolicyDialog,
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
  watch: {
    contact: {
      deep: true,
      handler(contact) {
        this.$emit('input', contact);
      },
    },
  },
  data() {
    return {
      contact: this.value,
      token: '',
    };
  },
  methods: {
    // #Documentation: https://github.com/AurityLab/vue-recaptcha-v3/tree/vue-v2.x
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      this.token = await this.$recaptcha('login');
    },
  }
};
</script>

<style scoped>

</style>
