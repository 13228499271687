import Model from './model';

export class Contact extends Model {
  name = '';
  email = '';
  company = null;
  phone = '';
  question = '';
  privacyPolicyAgreement = false

  mapForRequest() {
    return this;
  }
}
