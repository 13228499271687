<template>
  <div>
    Fis Automotive <br/>
    Slibbroek 44 <br/>
    5081 NS Hilvarenbeek
  </div>
</template>

<script>
export default {
  name: 'FisAddressInformation',
};
</script>
